import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import AuthIsLoaded from "./AuthIsLoaded";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  return (
    <AuthIsLoaded>
      <Route
        {...rest}
        render={({ location }) =>
          !isEmpty(auth) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location },
              }}
            />
          )
        }
      />
    </AuthIsLoaded>
  );
}

export default PrivateRoute;
