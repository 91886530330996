import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  useFirestoreConnect,
  useFirestore,
  isEmpty,
  isLoaded,
} from "react-redux-firebase";
import { useLocation } from "react-router-dom";
import ReactModal from "react-modal";
import DefaultTemplate from "../../templates/default";
import TIcon from "../../icon-svg/icon-svg";
import Gap from "../../gap/gap";
import "./post.scss";
import Loading from "../../../assets/images/spinner.png";

import createPostPdf from "../../../utils/pdf-manager";

const Tag = ({ tag }) => {
  const onTagDetail = () => {
    window.location.href = "/posts?type=tag-filter&search=" + tag.id;
  };

  return (
    <div className="tag" onClick={onTagDetail}>
      {tag !== undefined ? tag.text : "null"}
    </div>
  );
};

const Post = () => {
  const [showingSettingMenu, setShowingSettingMenu] = useState(false);
  const [showTrashModalOpen, setShowTrashModalOpen] = useState(false);

  const uid = useSelector((state) => state.firebase.auth.uid);
  const queryString = require("query-string");
  const parsed = queryString.parse(useLocation().search);
  const postId = parsed.key;

  useFirestoreConnect(() => [
    { collection: `Users/${uid}/Posts`, doc: postId, storeAs: "post" },
    { collection: `Users/${uid}/Publications`, storeAs: "publications" },
    { collection: `Users/${uid}/Tags` },
  ]);

  const post = useSelector(({ firestore: { data } }) => data && data.post);

  const publications = useSelector(
    ({ firestore: { data } }) => data.publications
  );

  const tags = useSelector(
    ({ firestore: { data } }) => data[`Users/${uid}/Tags`]
  );

  const firestore = useFirestore();

  const toggleShowSettingMenu = (event) => {
    event.preventDefault();

    setShowingSettingMenu(!showingSettingMenu);
  };

  const onOpenTrashModal = () => {
    setShowTrashModalOpen(true);
  };

  const onCloseTrashModal = () => {
    setShowTrashModalOpen(false);
  };

  const onTrashPost = async () => {
    await firestore.collection(`Users/${uid}/Posts`).doc(postId).delete();
    window.location.href = "/posts";
  };

  const onPostSearch = (type, con) => {
    window.location.href = "/posts?type=" + type + "&search=" + con;
  };

  const onExportPdf = () => {
    var filename = "Post" + postId + ".pdf";
    createPostPdf(
      { ...post, userId: uid },
      publications[post.publication],
      filename
    );
  };

  const renderSettingsMenu = () => (
    <div className="post-setting" align="right">
      <TIcon
        cl="btn-icon"
        icon="cog"
        wid="27"
        hei="27"
        fcolor="#757575"
        onIconClick={toggleShowSettingMenu}
      />
      {showingSettingMenu ? (
        <div className="sub-menu">
          <ul className="setting-menu">
            <li>
              <a href="/edit-title-excerpt">
                <div>Edit Post</div>
              </a>
            </li>
            <li>
              <a href="/edit-citation">
                <div>Edit Citation</div>
              </a>
            </li>
            <li>
              <a href="/edit-tags">
                <div>Edit Tags</div>
              </a>
            </li>
            <li>
              <a href="/edit-comment">
                <div>Edit Comment</div>
              </a>
            </li>
            <li>
              <div onClick={onExportPdf}>Export to PDF</div>
            </li>
            <li>
              <div onClick={onOpenTrashModal} className="trash">
                Trash
              </div>
            </li>
          </ul>
        </div>
      ) : null}
      <ReactModal
        ariaHideApp={false}
        isOpen={showTrashModalOpen}
        contentLabel=""
        onRequestClose={onCloseTrashModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(243, 236, 223	, 0.9)",
          },
          content: {
            position: "absolute",
            top: "153px",
            left: "calc(50% - 221px)",
            width: "442px",
            height: "231px",
            borderRadius: "5px",
            borderColor: "#e7e3db",
            padding: 0,
            background: "#fbf7f0",
          },
        }}
      >
        <div className="deactive-modal" align="center">
          <p>
            Are you sure you want to permanently delete this post from your
            archive?
          </p>
          <Gap hei="14" />
          <button className="confirm-deactive" onClick={onTrashPost}>
            Trash Post
          </button>
          <Gap hei="14" />
          <div className="cancel-deactive" onClick={onCloseTrashModal}>
            Nevermind
          </div>
        </div>
      </ReactModal>
    </div>
  );

  const getTags = () => {
    if (isLoaded(tags) && !isEmpty(tags)) {
      var tagsFilter = [];
      for (const [key, value] of Object.entries(tags)) {
        if (post.tags.includes(key)) {
          tagsFilter.push({ id: key, text: value.text });
        }
      }
      return tagsFilter;
    } else {
      return [];
    }
  };

  const renderPublication = () => {
    if (post.publication !== undefined && publications !== undefined) {
      return (
        <>
          <div
            className="citation"
            onClick={() => {
              onPostSearch("publication-filter", post.publication);
            }}
          >
            <div className="icon">
              <TIcon icon="book" wid="18" hei="18" fcolor="#757575" />
            </div>
            <span>
              {publications[post.publication].title !== undefined
                ? publications[post.publication].title
                : null}
            </span>
          </div>
          {publications[post.publication].author !== undefined ? (
            <div
              className="citation"
              onClick={() => {
                onPostSearch(
                  "author-filter",
                  publications[post.publication].coAuthor !== undefined ||
                    publications[post.publication].coAuthor === ""
                    ? publications[post.publication].author
                    : publications[post.publication].author +
                        ", " +
                        publications[post.publication].coAuthor
                );
              }}
            >
              <div className="icon">
                <TIcon icon="quote" wid="18" hei="20" fcolor="#757575" />
              </div>
              <span>
                {publications[post.publication].coAuthor === undefined ||
                publications[post.publication].coAuthor === ""
                  ? publications[post.publication].author
                  : publications[post.publication].author +
                    ", " +
                    publications[post.publication].coAuthor}
              </span>
            </div>
          ) : null}
          {post.page && post.page.length > 0 && (
            <div className="citation" style={{ cursor: "default" }}>
              <div className="icon">
                <TIcon icon="pushpin2" wid="18" hei="20" fcolor="#757575" />
              </div>
              <span>{post.page}</span>
            </div>
          )}
        </>
      );
    }
  };

  const renderPost = () => {
    console.log(post);
    if (post === undefined || post === null) {
      // Still loading
      return (
        <div className="loading-container" align="center">
          <img src={Loading} className="loading" alt="" />
        </div>
      );
    } else {
      // We have posts, render them
      window.localStorage.setItem(
        "single-post",
        JSON.stringify({ userId: uid, postId, ...post })
      );
      return (
        <div className="content">
          {renderSettingsMenu()}
          <div>
            <div className="citations">
              {post.addedDate === undefined ? null : (
                <div className="citation" style={{ cursor: "default" }}>
                  <div className="icon">
                    <TIcon icon="calendar" wid="18" hei="20" fcolor="#757575" />
                  </div>
                  <span>{post.addedDate.toDate().toLocaleDateString()}</span>
                </div>
              )}

              {renderPublication()}
            </div>

            <Gap hei="23" />
            <span className="post-title">{post.title}</span>
            <Gap hei="26" />
            <p
              className="post-content"
              id="post-content"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {post.description}
            </p>
            {getTags().length !== 0 ? (
              <>
                <Gap hei="32" />
                <div className="post-line"></div>
                <div className="tag-list">
                  {getTags().map((tag, index) => (
                    <Tag key={index} tag={tag} />
                  ))}
                </div>
              </>
            ) : null}
            <div className="post-line"></div>
            <Gap hei="36" />
            {post.comment && post.comment.length > 0 && (
              <div className="comment">
                <div className="comment-head">
                  <TIcon
                    icon="message-circle"
                    wid="20"
                    hei="20"
                    fcolor="#111111"
                  ></TIcon>
                  <span>Comments</span>
                </div>
                <Gap hei="29" />
                <p style={{ whiteSpace: "pre-wrap" }}>{post.comment}</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <DefaultTemplate>
      <div className="page-container post" align="left">
        <Gap hei="3" />
        {renderPost()}
      </div>
    </DefaultTemplate>
  );
};

export default Post;
