import React from "react";
import { render } from "react-dom";
import "./index.scss";
import App from "./App";

render(<App />, document.getElementById("root"));

// import React from 'react';
// import { render } from 'react-dom';
// import './index.scss';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import { Provider } from 'react-redux';
// import { createStore } from 'redux';
// import  postReducer from './reducer';

// const store = createStore(postReducer)

// render( <Provider store={store}><App /></Provider>, document.getElementById('root') );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
