import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

const fbConfig = {
  apiKey: "AIzaSyDvkBGfOs4377Xy0zQKFGBwy9iPEttBsf8",
  authDomain: "tertius-ios-firebase.firebaseapp.com",
  projectId: "tertius-ios-firebase",
  storageBucket: "tertius-ios-firebase.appspot.com",
  messagingSenderId: "744567665490",
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

const revenueCatAPI = "WjxNutvmaGgoNWEMqsRkMZhrAEhKKCtS";

export const isPro = async (uid) => {
  let res = await fetch(`https://api.revenuecat.com/v1/subscribers/${uid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${revenueCatAPI}`,
    },
  });

  let json = await res.json();
  return json.subscriber.entitlements.pro !== undefined;
};

export default firebase;

export const defaultSignedUrl = "/posts";
export const defaultUrl = "/";

export var googleProvider = new firebase.auth.GoogleAuthProvider();
