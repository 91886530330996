import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useLocation } from "react-router-dom";
import DefaultTemplate from "../../templates/default";
import TButton from "../../buttons/tbutton/tbutton";
import Gap from "../../gap/gap";
import PostItem from "../../postitem/postitem";
import Loading from "../../../assets/images/spinner.png";
import "./post.scss";

const PostsList = () => {
  const uid = useSelector((state) => state.firebase.auth.uid);
  useFirestoreConnect([
    {
      collection: `Users/${uid}/Posts`,
      storeAs: "posts",
      orderBy: ["addedDate", "desc"],
    },
    { collection: `Users/${uid}/Publications`, storeAs: "publications" },
    { collection: `Users/${uid}/Tags`, storeAs: "tags" },
  ]);
  const posts = useSelector((state) => state.firestore.ordered.posts);

  const publications = useSelector(
    (state) => state.firestore.data.publications
  );

  const tags = useSelector((state) => state.firestore.ordered.tags);

  const [postsLimit, setPostsLimit] = useState(25);
  const [postsToShowCount, setPostsToShowCount] = useState(0);

  const queryString = require("query-string");
  const parsed = queryString.parse(useLocation().search);
  const postpagetype = parsed.type;
  const search = parsed.search;

  const loadMorePosts = () => {
    setPostsLimit(postsLimit + 25);
  };

  const onSearch = (event) => {
    if (event.keyCode === 13) {
      if (document.getElementById("search").value === "") {
        alert("Please input search string.");
      } else {
        window.location.href =
          "/posts?type=search-filter&search=" +
          document.getElementById("search").value;
      }
    }
  };

  const getAllPosts = () => {
    if (postsToShowCount !== posts.length) {
      setPostsToShowCount(posts.length);
    }
    return posts.slice(0, postsLimit);
  };

  const getPostsWithTagsFilter = () => {
    var postsToShow = posts.filter((post) => {
      if (post.tags === undefined) {
        return false;
      }
      return post.tags.includes(search);
    });

    if (postsToShowCount !== postsToShow.length) {
      setPostsToShowCount(postsToShow.length);
    }
    return postsToShow.slice(0, postsLimit);
  };

  const getPostsWithAuthorFilter = () => {
    if (isLoaded(publications) && isLoaded(posts)) {
      return posts.filter((post) => {
        if (
          post.publication === undefined ||
          publications[post.publication].author === undefined
        ) {
          return false;
        } else {
          return publications[post.publication].author === search;
        }
        // publications.filter((pub) => pub.id === post.publication)[0]
        //   .author === search
      });
    } else {
      return [];
    }
  };

  const getPostsWithPublicationFilter = () => {
    if (publications !== undefined && posts !== undefined) {
      return posts.filter((post) => post.publication === search);
    } else {
      return [];
    }
  };

  const getPostsWithSearchFilter = () => {
    if (
      publications !== undefined &&
      posts !== undefined &&
      tags !== undefined
    ) {
      return posts.filter((post) => {
        // Search title, description, page
        if (
          post.title.toLowerCase().includes(search.toLowerCase()) ||
          post.description.toLowerCase().includes(search.toLowerCase()) ||
          (post.page && post.page.toLowerCase().includes(search.toLowerCase()))
        ) {
          return true;
        }

        // Publication title, author, coAuthor
        if (publications[post.publication] !== undefined) {
          const publication = publications[post.publication];
          if (
            publication.title.toLowerCase().includes(search.toLowerCase()) ||
            (publication.author &&
              publication.author
                .toLowerCase()
                .includes(search.toLowerCase())) ||
            (publication.coAuthor &&
              publication.coAuthor.toLowerCase().includes(search.toLowerCase()))
          ) {
            return true;
          }
        }

        // Tags
        if (post.tags !== undefined) {
          if (
            tags
              .filter((tag) => post.tags.includes(tag.id))
              .filter((tag) =>
                tag.text.toLowerCase().includes(search.toLowerCase())
              ).length > 0
          ) {
            return true;
          }
        }

        return false;
      });
    } else {
      return [];
    }
  };

  const renderPosts = () => {
    if (isLoaded(posts) && isLoaded(publications)) {
      // Loading finished
      if (posts.length === 0) {
        // Posts empty
        return (
          <div className="search-result">
            <span>No results found.</span>
          </div>
        );
      } else {
        // Loading finished and have posts
        var postsToRender = [];
        switch (postpagetype) {
          case undefined:
            postsToRender = getAllPosts();
            break;
          case "tag-filter":
            postsToRender = getPostsWithTagsFilter();
            break;
          case "author-filter":
            postsToRender = getPostsWithAuthorFilter();
            break;
          case "publication-filter":
            postsToRender = getPostsWithPublicationFilter();
            break;
          case "search-filter":
            postsToRender = getPostsWithSearchFilter();
            break;
        }

        return postsToRender.map((post, index) => {
          if (post.publication == undefined) {
            return <PostItem key={index} post={post} />;
          }
          return (
            <PostItem
              key={index}
              post={post}
              publication={publications[post.publication]}
            />
          );
        });
      }
    } else {
      // Loading not finished
      return (
        <div className="loading-container" align="center">
          <img src={Loading} className="loading" alt="" />
        </div>
      );
    }
  };

  return (
    <DefaultTemplate>
      <div className="page-container post-list" align="left">
        <div className="content postlist">
          {postpagetype === "publication-filter" && (
            <div className="filter-header">
              <span className="fh-title">sort by</span>
              <Gap hei="35" />
              <span className="filter-content">
                {isLoaded(publications) && publications[search].title}
              </span>
              <Gap hei="37" />
              <div className="filter-line"></div>
              <Gap hei="35" />
            </div>
          )}
          {postpagetype === "search-filter" && (
            <div className="filter-header">
              <span className="fh-title">search</span>
              <Gap hei="42" />
              <input
                type="text"
                id="search"
                defaultValue={search}
                onKeyUp={onSearch}
              />
              <Gap hei="4" />
              <div className="filter-line"></div>
              <Gap hei="35" />
            </div>
          )}
          {postpagetype === "tag-filter" && isLoaded(tags) && (
            <div className="filter-header">
              <span className="fh-title">tagged in</span>
              <Gap hei="35" />
              <div className="tag">
                {tags.filter((tag) => tag.id === search)[0].text}
              </div>
              <Gap hei="41" />
              <div className="filter-line"></div>
              <Gap hei="35" />
            </div>
          )}
          {postpagetype === "author-filter" && (
            <div className="filter-header">
              <span className="fh-title">sort by</span>
              <Gap hei="35" />
              <span className="filter-content">{search}</span>
              <Gap hei="37" />
              <div className="filter-line"></div>
              <Gap hei="35" />
            </div>
          )}
          <Gap hei="3" />
          {renderPosts()}
          <Gap hei="3" />
          {postsToShowCount > postsLimit && (
            <div className="action" align="center">
              <TButton
                btntype="btnframe btn-green"
                rpad="22"
                lpad="22"
                clickEvent={loadMorePosts}
              >
                Load more
              </TButton>
            </div>
          )}
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default PostsList;
