import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import DefaultTemplate from "../../templates/default";
import Gap from "../../gap/gap";
import TButton from "../../buttons/tbutton/tbutton";
import "./post.scss";

import firebase, { isPro } from "../../../config/firebase";

const EditCitation = () => {
  const post = JSON.parse(window.localStorage.getItem("single-post"));
  const userid = post.userId;
  const postkey = post.postId;
  const publicationId = post.publication;

  const [selectedPublication, setSelectedPublication] = useState();
  const [isPaidMember, setIsPaidMember] = useState(true);

  useFirestoreConnect([
    {
      collection: `Users/${post.userId}/Publications`,
      storeAs: "publications",
    },
  ]);

  const publications = useSelector(
    (state) => state.firestore.ordered.publications
  );

  useEffect(async () => {
    const test = await isPro(userid);
    console.log(test);
    setIsPaidMember(test);
  }, []);

  // const renderPublications = () => {
  //   if (isLoaded(publications) && !isEmpty(publications)) {
  //     return publications.map((publication, index) => (
  //       <div
  //         key={index}
  //         className="tag"
  //         onClick={() => selectPublication(publication)}
  //       >
  //         {publication.title}
  //       </div>
  //     ));
  //   }
  // };

  const selectPublication = (publication) => {
    updateState(setSelectedPublication, selectedPublication, publication);
  };

  const updatePublicationTitle = (e) => {
    const value = e.target.value;
    var currentPublication = { ...selectedPublication };
    currentPublication.title = value;
    setSelectedPublication(currentPublication);
  };

  const updatePublicationAuthor = (e) => {
    const value = e.target.value;
    var currentPublication = { ...selectedPublication };
    currentPublication.author = value;
    setSelectedPublication(currentPublication);
  };

  const updatePublicationCoAuthor = (e) => {
    const value = e.target.value;
    var currentPublication = { ...selectedPublication };
    currentPublication.coAuthor = value;
    setSelectedPublication(currentPublication);
  };

  const saveCitation = async () => {
    var pubId = selectedPublication.id;
    if (pubId === undefined) {
      // First time adding a publication
      const newId = await firebase
        .firestore()
        .collection(`Users/${userid}/Publications`)
        .add(selectedPublication);
      pubId = newId.id;
    } else {
      // Editing already saved publication
      await firebase
        .firestore()
        .doc(`Users/${userid}/Publications/${pubId}`)
        .update(selectedPublication);
    }

    // Update publication and page
    await firebase
      .firestore()
      .doc(`Users/${userid}/Posts/${postkey}`)
      .update({
        page: document.getElementById("pagenumber").value,
        publication: pubId,
      });

    window.location.href = "/post?key=" + postkey;
  };

  const cancel = () => {
    window.history.back();
  };

  const updateState = (stateUpdater, currentValue, newValue) => {
    if (currentValue !== newValue) {
      stateUpdater(newValue);
    }
  };

  const getPublicationForPost = () => {
    return publications.filter(
      (publication) => publication.id === publicationId
    )[0];
  };

  const renderPublicationEdit = () => {
    if (isLoaded(publications) && isLoaded(post) && !isEmpty(publications)) {
      if (selectedPublication === undefined) {
        // First render
        if (publicationId !== undefined) {
          // Post already has a publication
          updateState(
            setSelectedPublication,
            selectedPublication,
            getPublicationForPost()
          );
        } else {
          // Post has no publication already
          updateState(setSelectedPublication, selectedPublication, {
            title: "",
            author: "",
            coAuthor: "",
          });
        }
      }

      return (
        <>
          <div className="citation">
            <input
              type="text"
              id="pubtitle"
              onChange={updatePublicationTitle}
              value={selectedPublication && selectedPublication.title}
              placeholder="Publication"
            />
          </div>

          <div className="citation">
            <input
              type="text"
              id="author"
              onChange={updatePublicationAuthor}
              value={selectedPublication && selectedPublication.author}
              placeholder="Author"
            />
          </div>

          <div className="citation">
            <input
              type="text"
              id="coauthor"
              onChange={updatePublicationCoAuthor}
              value={selectedPublication && selectedPublication.coAuthor}
              placeholder="Co-author"
            />
          </div>

          <div className="citation">
            <input
              type="text"
              id="pagenumber"
              defaultValue={post.page}
              placeholder="PageNumber"
            />
          </div>
        </>
      );
    }
  };

  return (
    <DefaultTemplate headerType="edit-type">
      <div className="page-container page-filter edit-citation" align="left">
        <Gap hei="3" />
        <div className="content">
          {/* {isPaidMember && (
            <>
              <h3>Publications</h3>
              <div className="tags">{renderPublications()}</div>
            </>
          )} */}

          {renderPublicationEdit()}

          <div className="actions">
            <TButton
              btntype="btnframe btn-green"
              rpad="18"
              lpad="18"
              clickEvent={saveCitation}
            >
              Save
            </TButton>
            <TButton
              btntype="btnnoframe btn-gray"
              rpad="16"
              lpad="16"
              clickEvent={cancel}
            >
              Cancel
            </TButton>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default EditCitation;
