import React, { Component } from "react";
import DefaultTemplate from "../../templates/default";
import Gap from "../../gap/gap";
import TButton from "../../buttons/tbutton/tbutton";
import autosize from "autosize";
import "./post.scss";

import firebase from "../../../config/firebase";

class EditComment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: JSON.parse(window.localStorage.getItem("single-post")),
    };
  }

  componentDidMount() {
    this.textarea.focus();
    autosize(this.textarea);
  }

  saveComment() {
    var userid = this.state.post.userId;
    var postkey = this.state.post.postId;

    var comment = document.getElementById("post-comment").value;
    firebase
      .firestore()
      .doc(`Users/${userid}/Posts/${postkey}`)
      .update({ comment })
      .then(() => {
        window.location.href = "/post?key=" + postkey;
      });
  }

  cancelComment() {
    window.history.back();
  }

  render() {
    return (
      <DefaultTemplate headerType="edit-type">
        <div className="page-container edit-comment" align="left">
          <Gap hei="3" />
          <div className="content">
            <textarea
              id="post-comment"
              defaultValue={this.state.post.comment}
              ref={(c) => (this.textarea = c)}
            />
            <div className="actions">
              <TButton
                btntype="btnframe btn-green"
                rpad="18"
                lpad="18"
                clickEvent={this.saveComment.bind(this)}
              >
                Save
              </TButton>
              <TButton
                btntype="btnnoframe btn-gray"
                rpad="16"
                lpad="16"
                clickEvent={this.cancelComment.bind(this)}
              >
                Cancel
              </TButton>
            </div>
          </div>
        </div>
      </DefaultTemplate>
    );
  }
}

export default EditComment;
