import React, { Component } from 'react';
import DefaultTemplate from '../../templates/default';
import Gap from '../../gap/gap';
import './terms-of-service.scss';

class TermsOfService extends Component {
	render() {
	  	return (
	  		<DefaultTemplate>
				<div className="page-container terms-of-service" align="left">
					<div className="title">
						<span>terms of service</span>
					</div>

					<Gap hei="23"/>
					<div className="content">
						<p>Welcome to Tertius. We invite you to access our website and mobile app and use the Tertius service, but please note that your invitation is subject to your agreement with these Terms of Service. This document describes in detail your rights and our rights relating to the provision of the Service (as defined below), so please review these Terms carefully.<br /><br />
						<span>What Are the Terms of Service?</span><br /><br />
						The Terms of Service constitutes a contract between GB Ventures LLC (assumed name Tertius) and you. The Terms include the provisions set forth in this document and in the Tertius Privacy Policy and other terms or conditions that may be presented by us and accepted by you from time to time in connection with specific Service offerings (all of which we collectively refer to as the “Terms of Service” or “Terms”). If you do not agree to these Terms, you do not have the right to access or use our Service. If you do register for or otherwise use our Service you shall be deemed to confirm your acceptance of the Terms and your agreement to be a party to this binding contract.<br /><br />
						By using the Service, you acknowledge, accept and agree with all provisions of the Privacy Policy, including, without limitation, the use and treatment of the text, images, and other data you choose to input, upload, or store in Tertius (collectively, “Content”) and your personal information in accordance with such Privacy Policy.<br /><br />
						<span>What Is the Tertius Service?</span><br /><br />
						The Tertius Software (as defined below), the Tertius service, and other products, services and websites hosted or made available by Tertius, including, for example our Website, App and Help pages are collectively referred to in these Terms as the “Service”. In exchange for being enabled to use the Service, you agree to abide by these Terms.<br /><br />
						<span>If This Is a Contract, Who Are the Parties?</span><br /><br />
						You, the Account Holder, are one party to this contract. (An Account Holder is the person or entity who has contracted with Tertius as a User or Customer. The other party to this contract is GB Ventures LLC (assumed name Tertius), a limited liability company headquartered in Michigan. (GB Ventures LLC, assumed name Tertius, may be referred to in these Terms of Service as “Tertius”, “we” and sometimes “us).<br /><br />
						<span>Is This the Only Contract I Have with Tertius?</span><br /><br />
						It depends upon how you interact with the Tertius service and our software applications. If you install any Tertius Software on your computing devices, you may be asked to agree to an end user license agreement. If you pay for a Tertius subscription, you will be asked to agree to the Commercial Terms.<br /><br />
						<span>Will These Terms of Service Ever Change?</span><br /><br />
						These Terms may be amended as new features, technology, or legal requirements arise, so please check back from time to time. If we make a significant change, we’ll notify you and, where required, seek your consent.<br /><br />
						If we do update these Terms, you are free to decide whether to accept the updated terms or to stop using our Service (see “How is My Account Closed” below); your continued use of the Service after the effectiveness of that update will be deemed to represent your agreement with, and consent to be bound by, the new Terms. Except for changes made by us as described here, no other amendment or modification of these Terms shall be effective unless set forth in a written agreement expressly amending these Terms and bearing a written signature by you and us. For clarity, email or other communications will not constitute an effective written agreement for this purpose.<br /><br />
						<span>What Do I Have to do to Use the Tertius Service?</span><br /><br />
						First, you need to create a Tertius service account. You create an account by providing us with an email address. We refer to this as your “Basic Subscriber Information”. You are responsible for maintaining the accuracy, completeness and confidentiality of your Basic Subscriber Information, and you will be responsible for all activities that occur under your account, including activities of others to whom you have provided your Basic Subscriber  Information. We will not be liable for any loss or damage arising from your failure to provide us with accurate information or to keep your Basic Subscriber Information secure. If you discover any unauthorized use of your Basic Subscriber Information or suspect that anyone may be able to access your private Content without authorization, you should immediately notify our Customer Support team.<br /><br />
						Second, you will need to access your account through a web browser or by installing our client software on your computers, tablets and phones. Obtaining those devices and paying for their connectivity and data plans is your responsibility. Tertius also has no responsibility for the availability of the Internet and other telecommunication services necessary to access the Service.<br /><br />
						<span>Can I Share My Account with Someone Else?</span><br /><br />
						Tertius service accounts should not be shared. If you share your Basic Subscriber Information with anyone, that other person may be able to take control of the account, and we may not be able to determine who is the proper Account Holder. We will not have any liability to you (or anyone you share your Basic Subscriber Information with) as a result of your or their actions under those circumstances. We strongly urge you not to share your information with anyone, unless you are doing so as part of your estate planning purposes, as discussed below.<br /><br />
						<span>Once I Have an Account, What Are My Rights in the Tertius Service?</span><br /><br />
						Once your account is created and you accept these Terms, we grant you a limited, non-exclusive license to use the Service subject to these Terms, for so long as you are not barred from receiving the Service under the laws applicable to you, until you close your account voluntarily or until we close your account pursuant to these Terms. In addition, we grant you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the Tertius Software provided to you by or on behalf of Tertius, for the sole purpose of enabling you to use the Tertius Software and enjoy the benefit of the Service, subject to any applicable license terms provided with the Tertius Software and these Terms, until your rights are terminated in accordance with such license and/or these Terms. You do not obtain any other right or interest in Tertius or the Service.<br /><br />
						<span>Tertius’s Data Protection Laws Say My Data Is Mine – What Does That Mean?</span><br /><br />
						You retain copyright and any other rights you already held in your Content before you submitted, posted or displayed it on or through the Service. But you do have to grant Tertius a limited license, as described below, so we can make your data accessible and usable on the Service. Other than this limited license and other rights you grant in these Terms, Tertius acknowledges and agrees that we do not obtain any right, title or interest from you under these Terms in any of your Content.<br /><br />
						<span>What Is the License I Have to Grant to Tertius?</span><br /><br />
						In order to enable Tertius to operate the Service, we must obtain from you certain limited license rights to process your Content that is covered by intellectual property rights so that technical actions we take in operating the Service are not considered legal violations. For example, copyright laws could prevent us from processing, maintaining, storing, backing-up and distributing certain Content, unless you give us these rights. Accordingly, by using the Service and uploading Content, you are granting Tertius a license to display, perform and distribute your Content and to modify (for technical purposes, e.g., making sure content is viewable on smartphones as well as computers and other devices) and reproduce such Content to enable Tertius to operate the Service. You also agree that Tertius has the right to elect not to accept, post, store, display, publish or transmit any Content in our sole discretion. <br /><br />
						You agree that these rights and licenses are royalty free, transferable, sub-licensable, worldwide and irrevocable (for so long as your Content is stored with us), and include a right for Tertius to make such Content available to, and pass these rights along to, others with whom Tertius has contractual relationships related to the provision of the Service, solely for the purpose of providing such services, and to otherwise permit access to or disclose your Content to third parties if Tertius determines such access is necessary to comply with its legal obligations.<br /><br />
						If you elect to use any third party service or application that is integrated with Tertius, you also agree that the licenses granted to Tertius in the preceding paragraph shall apply to Content that is submitted or uploaded through such third party service or application. If the third party service or application you elect to use would access or extract Content, you grant Tertius the right and license to enable third party access to and extraction of your Content. Tertius does not assume any responsibility for, or liability on account of, the actions or omissions of such third party applications or service providers.<br /><br />
						As we rely upon your rights to upload and distribute your Content, you represent and warrant to Tertius that (1) you have the unfettered legal rights and authority to submit your Content to Tertius, to make any other use, publication or other distribution of that Content in your use of the Service, and to grant the rights granted to Tertius under these Terms; and (2) your Content complies with our User Guidelines and these Terms.<br /><br />
						Finally, you understand and agree that Tertius, in performing the required technical steps to provide the Service to our users, may make such changes to your Content as are necessary to conform and adapt that Content to the technical requirements of connecting networks, devices, services or media. <br /><br />
						<span>What Other Assurances Do I Have to Grant to Tertius?</span><br /><br />
						When you use the Tertius service to send messages to an email address, to a telephone number via SMS, or through a social media account (e.g., Facebook or LinkedIn) that has not been linked to an Tertius account, Tertius sends such messages on your behalf, and we are relying on your representation to us that you have a direct relationship with the recipient(s) and that you are respecting the legal rights of the recipient(s) not to receive certain kinds of messages (such as harassing messages, unsolicited commercial messages, and unwanted SMS messages). Whenever you send any kind of message to a third party you represent and warrant to Tertius that you are acting within the law and that you have prior consent from the recipient to send them such a message.<br /><br />
						<span>Are There Rules about What I Can Do on the Tertius Service?</span><br /><br />
						Yes. Your use of the Service must be in accordance with these Terms. When it comes to your use of the Service, you agree that you are responsible for your own conduct and all conduct under your account. This means all Content created, transmitted, stored or displayed in your account, is your sole responsibility as the person who created the Content or introduced it into the Service. This applies whether the Content is kept private, shared or transmitted using the Service or any third-party application or services integrated with the Tertius service. Our User Guidelines provide more specific details regarding prohibited conduct on the Service. If we find that any shared Content in your account violates our Terms of Service (including by violating another person’s intellectual property or privacy rights), we reserve the right to un-share or take down such content.<br /><br />
						<span>I’m Guessing Tertius Has Some Rights Relating to the Service?</span><br /><br />
						We do. They’re described here:<br /><br />
						CONTENT RIGHTS.<br /><br />
						While you own the Content you store within the Tertius service (subject to third party rights), you acknowledge and agree that Tertius (and our licensors) own(s) all legal right, title and interest in and to the Service, including, without limitation, all software that is part of the Service and all Tertius software deployed by you or a third party to enable capturing of Content originating outside the Service, such as Tertius Scannable, Tertius Web Clipper, the Site Memory widget or any of the Tertius software applications for compatible computing devices that enable access and use of the Service through such device (the “Tertius Software”).<br /><br /></p>
					</div>
				</div>
			</DefaultTemplate>
	    );
	}
}

export default TermsOfService;