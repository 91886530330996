import React from "react";
import { Provider } from "react-redux";

import { createStore, combineReducers } from "redux";
import { ReduxFirestoreProvider, firebaseReducer } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

import Home from "./components/pages/home/home";

import Signin from "./components/pages/sign/signin";
import Subscribe from "./components/pages/sign/subscribe";

import Search from "./components/pages/search/search";
import Filter from "./components/pages/filter/filter";

import Post from "./components/pages/posts/post";
import EditTitleExcerpt from "./components/pages/posts/edit-title-excerpt";
import EditTags from "./components/pages/posts/edit-tags";
import EditComment from "./components/pages/posts/edit-comment";
import EditCitation from "./components/pages/posts/edit-citation";
import Settings from "./components/pages/settings/settings";

import TermsOfService from "./components/pages/terms-of-service/terms-of-service";
import PrivacyPolicy from "./components/pages/privacy-policy/privacy-policy";
import "./App.scss";
import PostsList from "./components/pages/posts/PostsList";
import firebase from "./config/firebase";
import Welcome from "./components/pages/welcome/Welcome";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "Users",
  useFirestoreForProfile: true,
};

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

// Setup react-redux so that connect HOC can be used
const App = () => {
  return (
    <Provider store={store}>
      <ReduxFirestoreProvider {...rrfProps}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/terms" component={TermsOfService} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/signin" component={Signin} />
            <Route exact path="/subscribe" component={Subscribe} />
            <Route exact path="/welcome" component={Welcome} />

            <PrivateRoute exact path="/search">
              <Search />
            </PrivateRoute>

            <PrivateRoute exact path="/filter">
              <Filter />
            </PrivateRoute>

            <PrivateRoute exact path="/posts">
              <PostsList />
            </PrivateRoute>

            <PrivateRoute exact path="/post">
              <Post />
            </PrivateRoute>

            <PrivateRoute exact path="/edit-title-excerpt">
              <EditTitleExcerpt />
            </PrivateRoute>

            <PrivateRoute exact path="/edit-tags">
              <EditTags />
            </PrivateRoute>

            <PrivateRoute exact path="/edit-comment">
              <EditComment />
            </PrivateRoute>

            <PrivateRoute exact path="/edit-citation">
              <EditCitation />
            </PrivateRoute>

            <PrivateRoute exact path="/settings">
              <Settings />
            </PrivateRoute>
          </Switch>
        </BrowserRouter>
      </ReduxFirestoreProvider>
    </Provider>
  );
};

export default App;
