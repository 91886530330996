import React, { Component } from "react";
import logo from "../../../assets/images/sign-logo.png";
import appleLogo from "../../../assets/images/apple_logo.png";
import Gap from "../../gap/gap";
import SignButton from "../../buttons/sign-button/sign-button";
import { Redirect } from "react-router-dom";
import "./sign.scss";

import firebase, {
  defaultSignedUrl,
  googleProvider,
} from "../../../config/firebase";

class Signin extends Component {
  constructor() {
    super();
    this.state = {
      signed: false,
    };
  }

  componentWillMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          signed: true,
        });
      } else {
        this.setState({
          signed: false,
        });
      }
    });
  }

  signinWithGoogle() {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        //console.log(user);
        window.location.href = defaultSignedUrl;
      }
    });

    googleProvider.addScope(
      "https://www.googleapis.com/auth/contacts.readonly"
    );
    firebase.auth().languageCode = "us";
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then(function (result) {})
      .catch(function (error) {
        //error occur
        console.log(error);
      });
  }

  signInWithApple() {
    var provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // The signed-in user info.
        var user = result.user;

        // You can also get the Apple OAuth Access and ID Tokens.
        var accessToken = credential.accessToken;
        var idToken = credential.idToken;

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        // ...
      });
  }

  render() {
    return this.state.signed ? (
      <Redirect to={defaultSignedUrl} />
    ) : (
      <div className="sign-page" align="center">
        <div className="sign-container">
          <div className="logo" align="center">
            <img src={logo} alt="Tertius logo" />
          </div>

          <div className="main-block" align="center">
            <span className="title">welcome back</span>
            <Gap hei="2"></Gap>
            <p>Sign in to your existing account.</p>
            <Gap hei="3"></Gap>
            <div className="signin-action">
              <SignButton
                icon="google"
                clickEvent={this.signinWithGoogle.bind(this)}
              >
                Sign in with Google
              </SignButton>

              <Gap hei="18"></Gap>

              <SignButton
                icon="apple"
                clickEvent={this.signInWithApple.bind(this)}
              >
                Sign in with Apple
              </SignButton>
              <Gap hei="18"></Gap>
              <span>
                Don’t have a subscription?&nbsp;<a href="/subscribe">Sign up</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
