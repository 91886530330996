import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import DefaultTemplate from "../../templates/default";
import Gap from "../../gap/gap";

import "./filter.scss";
import fire from "../../../config/firebase";
import Loading from "../../../assets/images/spinner.png";

const Filter = () => {
  const uid = useSelector((state) => state.firebase.auth.uid);
  useFirestoreConnect([
    {
      collection: `Users/${uid}/Tags`,
      storeAs: "tags",
      queryParams: ["orderByChild=text"],
    },
    {
      collection: `Users/${uid}/Publications`,
      storeAs: "publications",
    },
  ]);
  const tags = useSelector((state) => state.firestore.ordered.tags);
  const publications = useSelector(
    (state) => state.firestore.ordered.publications
  );

  const [selectedFilter, setSelectedFilter] = useState("tags");

  const onTagDetail = (tag) => {
    window.location.href = "/posts?type=tag-filter&search=" + tag.id;
  };

  const onPublicationDetail = (publication) => {
    window.location.href =
      "/posts?type=publication-filter&search=" + publication.id;
  };

  const onAuthorDetail = (author) => {
    window.location.href = "/posts?type=author-filter&search=" + author;
  };

  const renderTags = () => {
    if (isLoaded(tags) && !isEmpty(tags)) {
      return tags.map((tag, index) => (
        <div key={index} className="tag" onClick={() => onTagDetail(tag)}>
          {tag.text}
        </div>
      ));
    }
  };

  const renderPublications = () => {
    if (isLoaded(publications) && !isEmpty(publications)) {
      return publications.map((publication, index) => (
        <div
          key={index}
          className="tag"
          onClick={() => onPublicationDetail(publication)}
        >
          {publication.title}
        </div>
      ));
    }
  };

  const renderAuthor = () => {
    if (isLoaded(publications) && !isEmpty(publications)) {
      var authorsToRender = [];
      var authorsArray = publications
        .filter(
          (publication) =>
            publication.author !== undefined && publication.author.length > 0
        )
        .map((publication) => publication.author);
      authorsArray.forEach((author) => {
        if (!authorsToRender.includes(author)) {
          authorsToRender.push(author);
        }
      });
      return authorsToRender.map((author, index) => (
        <div key={index} className="tag" onClick={() => onAuthorDetail(author)}>
          {author}
        </div>
      ));
    }
  };

  return (
    <DefaultTemplate pageType="filter">
      <div className="page-container page-filter" align="left">
        <Gap hei="3" />
        <div className="content">
          <span>FILTER</span>
          <div className="filterMenu">
            <span
              style={{
                marginLeft: "50px",
                color: selectedFilter === "tags" ? "black" : "#757575",
                textTransform: "uppercase",
              }}
              onClick={() => setSelectedFilter("tags")}
            >
              Tag
            </span>
            <span
              style={{
                marginLeft: "50px",
                color: selectedFilter === "publications" ? "black" : "#757575",
                textTransform: "uppercase",
              }}
              onClick={() => setSelectedFilter("publications")}
            >
              Publication
            </span>
            <span
              style={{
                marginLeft: "50px",
                color: selectedFilter === "author" ? "black" : "#757575",
                textTransform: "uppercase",
              }}
              onClick={() => setSelectedFilter("author")}
            >
              Author
            </span>
          </div>
          <Gap hei="15" />
          <div className="post-line"></div>
          {!isLoaded(publications) && !isLoaded(tags) && (
            <div className="loading-container" align="center">
              <img src={Loading} className="loading" alt="" />
            </div>
          )}
          <div className="tags">
            {selectedFilter === "tags"
              ? renderTags()
              : selectedFilter === "publications"
              ? renderPublications()
              : renderAuthor()}
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Filter;
