import React from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Loading from "../assets/images/spinner.png";

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <div className="loading-container" align="center">
        <img src={Loading} className="loading" alt="" />
      </div>
    );
  return children;
};

export default AuthIsLoaded;
