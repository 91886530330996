import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import DefaultTemplate from "../../templates/default";
import Gap from "../../gap/gap";
import TIcon from "../../icon-svg/icon-svg";
import TButton from "../../buttons/tbutton/tbutton";
import "./post.scss";
import firebase from "../../../config/firebase";
import Loading from "../../../assets/images/spinner.png";

const EditTags = () => {
  const post = JSON.parse(window.localStorage.getItem("single-post"));

  useFirestoreConnect([
    { collection: `Users/${post.userId}/Tags`, storeAs: "tags" },
  ]);

  const tags = useSelector((state) => state.firestore.ordered.tags);
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);

  const getNewId = () => {
    const ref = firebase
      .firestore()
      .collection(`Users/${post.userId}/Tags`)
      .doc();
    return ref.id;
  };

  const getTagsToShow = () => {
    if (isLoaded(tags)) {
      return [...getTagsForPost(), ...tagsToAdd].filter(
        (tag) => !tagsToRemove.includes(tag)
      );
    } else {
      return [];
    }
  };

  const getAllTags = () => {
    if (isLoaded(tags)) {
      return [...tagsToAdd, ...tags.filter((tag) => !tagsToAdd.includes(tag))];
    } else {
      return [];
    }
  };

  const getTagsForPost = () => {
    if (isLoaded(tags)) {
      console.log(tags.filter((tag) => post.tags.includes(tag.id)));
      return tags.filter((tag) => post.tags.includes(tag.id));
    } else {
      return [];
    }
  };

  const deleteTags = (tag) => {
    if (getTagsForPost().includes(tag)) {
      // Tag to be removed is already in database
      setTagsToRemove([...tagsToRemove, tag]);
    } else {
      // Tag to be removed was added just now and deleted before saving
      setTagsToAdd(tagsToAdd.filter((tagIn) => tagIn !== tag));
    }
  };

  const addTags = () => {
    var tagname = document.getElementById("add_tag").value.trim();
    if (tagname === "") {
      document.getElementById("add_tag").value = "";
      return;
    }
    setTagsToAdd([...tagsToAdd, { id: getNewId(), text: tagname }]);
    document.getElementById("add_tag").value = "";
  };

  const inputedTags = (event) => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      addTags();
    }
  };

  const saveTags = async () => {
    if (tagsToAdd.length > 0) {
      // Adding new tags
      await tagsToAdd.map(async (tag) => {
        await firebase
          .firestore()
          .doc(`Users/${post.userId}/Tags/${tag.id}`)
          .set({ text: tag.text });
      });
    }

    await firebase
      .firestore()
      .doc(`Users/${post.userId}/Posts/${post.postId}`)
      .update({
        tags: [...getTagsForPost(), ...tagsToAdd]
          .filter((tag) => !tagsToRemove.includes(tag))
          .map((tag) => tag.id),
      });

    window.location.href = "/post?key=" + post.postId;
  };

  const cancelTags = () => {
    window.history.back();
  };

  const tagBelongsToPost = (tag) => {
    if (post !== undefined) {
      return (
        (post.tags.includes(tag.id) && !tagsToRemove.includes(tag)) ||
        tagsToAdd.includes(tag)
      );
    }

    return false;
  };

  return (
    <DefaultTemplate headerType="edit-type">
      <div className="page-container edit-tags" align="left">
        <Gap hei="5" />
        {!isLoaded(tags) && (
          <div className="loading-container" align="center">
            <img src={Loading} className="loading" alt="" />
          </div>
        )}
        <div className="content">
          <div className="tags">
            {tags === undefined
              ? null
              : getAllTags().map((tag, index) => (
                  <div
                    className="tag"
                    key={index}
                    style={{
                      backgroundColor: tagBelongsToPost(tag)
                        ? "rgb(215, 230, 214)"
                        : "white",
                    }}
                    onClick={() => {
                      if (!tagBelongsToPost(tag)) {
                        setTagsToAdd([...tagsToAdd, tag]);
                      }
                    }}
                  >
                    <span>{tag.text}</span>
                    {tagBelongsToPost(tag) && (
                      <div
                        className="btn-close"
                        onClick={() => deleteTags(tag)}
                      >
                        <TIcon
                          cl="btn-icon"
                          icon="x"
                          wid="12"
                          hei="12"
                          fcolor="#757575"
                        />
                      </div>
                    )}
                  </div>
                ))}
            <input
              id="add_tag"
              className="input_tag"
              type="text"
              placeholder="Add a tag..."
              onBlur={addTags}
              onKeyUp={inputedTags}
            />
          </div>
          <div className="actions">
            <TButton
              btntype="btnframe btn-green"
              rpad="20"
              lpad="18"
              clickEvent={saveTags}
            >
              Save
            </TButton>
            <TButton
              btntype="btnnoframe btn-gray"
              rpad="16"
              lpad="16"
              clickEvent={cancelTags}
            >
              Cancel
            </TButton>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default EditTags;
