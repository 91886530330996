import React, { Component } from "react";
import firebase from "../../../config/firebase";
import DefaultTemplate from "../../templates/default";
import Gap from "../../gap/gap";
import TButton from "../../buttons/tbutton/tbutton";
import TextareaAutosize from "react-autosize-textarea";

import "./post.scss";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import "font-awesome/css/font-awesome.css";

class EditTitleExcerpt extends Component {
  constructor(props) {
    super(props);

    var editPost = JSON.parse(window.localStorage.getItem("single-post"));
    console.log(editPost);
    this.state = {
      model: editPost.description,
      post: editPost,
    };
  }

  componentDidMount() {
    this.textarea_title.focus();
    var titlearea = document.getElementById("post-title");
    titlearea.addEventListener("keypress", this.onPreventPressEnter);
  }

  onPreventPressEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  saveTitleExcerpt() {
    var userid = this.state.post.userId;
    var postkey = this.state.post.postId;

    firebase
      .firestore()
      .doc(`Users/${userid}/Posts/${postkey}`)
      .update({
        title: document.getElementById("post-title").value,
        description: this.state.model
          .replaceAll("</p>", "\n")
          .replace(/(<([^>]+)>)/gi, "")
          .replaceAll("&nbsp;", ""),
      })
      .then(() => {
        window.location.href = "/post?key=" + postkey;
      });
  }

  cancelTitleExcerpt() {
    window.history.back();
  }

  handleChange = (event) => {
    this.setState({ model: event.target.value });
  };

  render() {
    var handleChange = this.handleChange;
    return (
      <DefaultTemplate
        headerType="edit-type"
        pageType="edit-title-excerpt-type"
      >
        <div className="page-container edit-title-excerpt" align="left">
          <Gap hei="3" />
          <div className="content">
            <div className="post-actions" align="right">
              <div className="buttons">
                <TButton
                  btntype="btnnoframe btn-gray"
                  rpad="20"
                  lpad="18"
                  clickEvent={this.cancelTitleExcerpt.bind(this)}
                >
                  Cancel
                </TButton>
                <TButton
                  btntype="btnframe btn-green"
                  rpad="14"
                  lpad="12"
                  clickEvent={this.saveTitleExcerpt.bind(this)}
                >
                  Save and close
                </TButton>
              </div>
            </div>
            <div className="post-title">
              <TextareaAutosize
                id="post-title"
                defaultValue={this.state.post.title}
                innerRef={(c) => (this.textarea_title = c)}
              />
            </div>
            <div className="post-excerpt">
              <p className="excerpt-para">
                <textarea
                  value={this.state.model}
                  onChange={handleChange}
                  placeholder="Type your text"
                  className="medium-editor-element"
                  style={{
                    outline: "dotted 1px",
                    padding: 10,
                    minHeight: "60vh",
                  }}
                ></textarea>
                {/* <Editor
                  style={{ outline: "dotted 1px", padding: 10 }}
                  text={this.state.model}
                  onChange={handleChange}
                  options={{
                    buttonLabels: "fontawesome",
                    toolbar: {
                      buttons: [
                        "bold",
                        "italic",
                        "anchor",
                        "h2",
                        "h3",
                        "quote",
                      ],
                    },
                  }}
                /> */}
              </p>
            </div>
          </div>
        </div>
      </DefaultTemplate>
    );
  }
}

export default EditTitleExcerpt;
