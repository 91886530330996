import { jsPDF } from "jspdf";
import Post from "../components/pages/posts/post";

const createPostPdf = (post, publication, filename) => {
  const newPost = post;
  var newPublication = publication;
  if (publication === undefined) {
    newPublication = { title: "N/A", author: "N/A", coAuthor: "N/A" };
  }
  var pdf = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
  });

  if (post.page === undefined || post.page.length === 1) {
    newPost.page = "N/A";
  }

  if (post.comment === undefined) {
    newPost.comment = "N/A";
  }

  var html_content =
    "<div class='pdf-content'>" +
    "<style>h3.pdfelement { font-family: 'Helvetica', Arial, sans-serif; font-weight: bold; font-size: 14.66px; font-weight: bold; margin: 0 0 16.25px 0; } p.pdfelement { font-family: 'Times New Roman', Times, serif; font-size: 14.66px; font-weight: normal; margin: 0 0 17.08px 0; }</style>" +
    "<h3 class='pdfelement'>Date</h3><p class='pdfelement'>" +
    post.addedDate.toDate().toLocaleDateString() +
    "</p>" +
    "<h3 class='pdfelement'>Publication</h3><p class='pdfelement' style='width: 300pt'>" +
    newPublication.title +
    "</p>" +
    "<h3 class='pdfelement'>Author(s)</h3><p class='pdfelement' style='width: 300pt'>" +
    newPublication.author +
    (newPublication.coAuthor !== "N/A"
      ? "<br />" + newPublication.coAuthor
      : "") +
    "</p>" +
    "<h3 class='pdfelement'>Page(s)</h3><p class='pdfelement'>" +
    newPost.page +
    "</p>" +
    "<style>p.pdfelement1 { font-family: 'Times New Roman', Times, serif; font-size: 14.66px; font-weight: normal; margin: 0 0 22.42px 0; }</style>" +
    "<h3 class='pdfelement' style='width: 300pt'>Post Title</h3><p class='pdfelement1' style='width: 300pt'>" +
    newPost.title +
    "</p>" +
    "<style>h3.pdfelement2 { white-space: pre-wrap; font-family: 'Helvetica', Arial, sans-serif; font-weight: bold; font-size: 14.66px; font-weight: bold; margin: 0 0 13.58px 0; } p.pdfelement2 { font-family: 'Times New Roman', Times, serif; font-size: 14.66px; font-weight: normal; margin: 0 0 28.1px 0; line-height: 1.45}</style>" +
    "<h3 class='pdfelement2'>Excerpt/Entry</h3><p class='pdfelement2' id='post_content' style='width: 330pt'></p>" +
    "<style>h3.pdfelement3 { font-family: 'Helvetica', Arial, sans-serif; font-weight: bold; font-size: 14.66px; font-weight: bold; margin: 0 0 12.52px 0; } p.pdfelement3 { font-family: 'Times New Roman', Times, serif; font-size: 14.66px; font-weight: normal; margin: 0 0 0 0; line-height: 1.46}</style>" +
    "<h3 class='pdfelement3'>Comments</h3><p class='pdfelement3' style='width: 330pt'>" +
    newPost.comment +
    "</p></div>";

  var sec = document.createElement("div");
  const pdf_element_id = "b1a5f625166c487c93e0c9ecf84f0b58";
  sec.id = pdf_element_id;
  sec.innerHTML = html_content;

  document.body.appendChild(sec);
  document.getElementById("post_content").innerHTML = post.description;
  document.getElementById("post_content").innerHTML = document.getElementById(
    "post_content"
  ).textContent;

  pdf.html(document.getElementById(pdf_element_id).firstChild, {
    margin: [72, 40, 72, 70],
    x: 72,
    y: 65,
    callback: function (doc) {
      doc.save(filename);
    },
  });

  // pdf.fromHTML(
  //   document.getElementById(pdf_element_id).firstChild,
  //   margins.left, // x coord
  //   margins.top,
  //   {
  //     width: 436, // max width of content on PDF
  //   },
  //   function (dispose) {},
  //   margins
  // );

  document.getElementById(pdf_element_id).remove();
  return pdf;
};

export default createPostPdf;
