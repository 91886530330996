import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import ReactModal from "react-modal";
import DefaultTemplate from "../../templates/default";
import TButton from "../../buttons/tbutton/tbutton";
import Gap from "../../gap/gap";
import "./settings.scss";
import firebase from "../../../config/firebase";
import createPostPdf from "../../../utils/pdf-manager";
import Loading from "../../../assets/images/spinner.png";
import * as EmailValidator from "email-validator";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const Settings = () => {
  const [confirmationText, setConfirmationText] = useState("");
  const [accountModalOpen, setAccountModalOpen] = useState(false);

  const uid = useSelector((state) => state.firebase.auth.uid);
  useFirestoreConnect([
    {
      collection: `Users/${uid}/Posts`,
      storeAs: "posts",
      queryParams: ["orderByChild=addedDate"],
    },
    { collection: `Users/${uid}/Publications`, storeAs: "publications" },
  ]);
  const posts = useSelector((state) => state.firestore.ordered.posts);

  const publications = useSelector(
    (state) => state.firestore.data.publications
  );

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  const onOpenAccountModal = () => {
    setAccountModalOpen(true);
  };

  const onCloseAccountModal = () => {
    setAccountModalOpen(false);
  };

  const onDelectAccount = () => {
    firebase.auth().currentUser.delete();
  };

  const downloadZip = () => {
    if (posts.length === 0) {
      return;
    } else {
      var zip = new JSZip();
      posts.forEach((post) => {
        var filename = "post" + post.id + ".pdf";
        var pdf = createPostPdf(post, publications[post.publication], filename);
        zip.file(filename, pdf.output("blob"));
      });
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      saveAs(content, "posts.zip");
    });
  };

  return (
    <DefaultTemplate>
      <div className="page-container settings" align="left">
        <div className="title">
          <span>settings</span>
        </div>
        <div className="content sections">
          <div className="section">
            <div className="section-title">
              <span>Download archive</span>
            </div>
            <Gap hei="14"></Gap>
            <p>
              Download a copy of the content you’ve archived on Tertius to a
              .zip file.
            </p>
            <Gap hei="19"></Gap>
            <div className="">
              <TButton
                rpad="18"
                lpad="18"
                btntype="btnframe btn-gray"
                clickEvent={downloadZip}
              >
                Download .zip
              </TButton>
            </div>
            <Gap hei="14"></Gap>
          </div>

          <div className="section">
            <div className="section-title">
              <span>Delete account</span>
            </div>
            <Gap hei="14"></Gap>
            <p>Permanently delete your account and all of your content.</p>
            <Gap hei="19"></Gap>
            <div className="link" onClick={onOpenAccountModal}>
              <span>Delete account</span>
            </div>
            <ReactModal
              isOpen={accountModalOpen}
              contentLabel=""
              onRequestClose={onCloseAccountModal}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(243, 236, 223	, 0.9)",
                },
                content: {
                  position: "absolute",
                  top: "153px",
                  left: "calc(50% - 221px)",
                  width: "442px",
                  height: "454px",
                  borderRadius: "5px",
                  borderColor: "#e7e3db",
                  padding: 0,
                  background: "#fbf7f0",
                },
              }}
            >
              <div className="account-delete-modal" align="center">
                <p>
                  We’re sorry to see you go. Once your account is deleted, all
                  of your content will be lost forever. If you’re not sure about
                  that, we suggest you deactivate or contact support@tertius.app
                  instead.
                  <br />
                  <br />
                  To confirm deletion, please type <br />
                  "DELETE PERMANENTLY" and click ‘Delete account’
                </p>
                <Gap hei="5" />
                <input
                  className="contract-email"
                  id="contact-email"
                  type="text"
                  value={confirmationText}
                  onChange={(e) => {
                    setConfirmationText(e.target.value);
                  }}
                />
                <Gap hei="35" />
                <button
                  disabled={confirmationText !== "DELETE PERMANENTLY"}
                  className="confirm-account-delete"
                  onClick={onDelectAccount}
                >
                  Delete account
                </button>
                <Gap hei="11" />
                <div
                  className="cancel-account-delete"
                  onClick={onCloseAccountModal}
                >
                  Nevermind
                </div>
              </div>
            </ReactModal>
            <Gap hei="14"></Gap>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Settings;
