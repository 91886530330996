import React from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import TIcon from "../icon-svg/icon-svg";
import Gap from "../gap/gap";
import "./postitem.scss";

const PostItem = ({ post, publication }) => {
  const postDetailView = () => {
    window.location.href = "/post?key=" + post.id;
  };

  const renderPost = () => (
    <div className="post-item-content">
      <div className="citation">
        <div className="icon">
          <TIcon icon="calendar" wid="18" hei="20" fcolor="#757575" />
        </div>
        <span>
          {post.addedDate !== undefined
            ? post.addedDate.toDate().toLocaleDateString()
            : null}
        </span>
      </div>
      <Gap hei="23" />
      <span className="post-title" onClick={postDetailView}>
        {post.title}
      </span>
      <Gap hei="5" />
      <p
        className="post-content"
        style={{ WebkitBoxOrient: "vertical", whiteSpace: "pre-wrap" }}
      >
        {post.description}
      </p>
      {publication && publication.title && (
        <>
          <Gap hei="11" />
          <div className="citation">
            <div className="icon">
              <TIcon icon="book" wid="18" hei="18" fcolor="#757575" />
            </div>
            <span>{publication && publication.title}</span>
          </div>
        </>
      )}

      {publication && publication.author && (
        <div className="citation">
          <div className="icon">
            <TIcon icon="quote" wid="18" hei="20" fcolor="#757575" />
          </div>
          <span>
            {publication !== undefined
              ? publication.author === undefined
                ? ""
                : publication.author
              : ""}
            {publication !== undefined
              ? publication.coAuthor === undefined ||
                publication.coAuthor === ""
                ? ""
                : ", " + publication.coAuthor
              : ""}
          </span>
        </div>
      )}

      {post.page && post.page.length > 0 && (
        <div className="citation">
          <div className="icon">
            <TIcon icon="pushpin2" wid="18" hei="20" fcolor="#757575" />
          </div>
          <span>{post.page}</span>
        </div>
      )}
    </div>
  );

  return (
    <div className="post-item">
      {post !== undefined && post !== null ? renderPost() : null}

      <Gap hei="34" />
      <div className="post-line"></div>
      <Gap hei="39" />
    </div>
  );
};

export default PostItem;
